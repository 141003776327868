<template>
  <div class="trainVideo">
    <div class="container" v-if="!showDetail">
      <div
        class="videoContainer"
        v-for="(item, index) in videoList"
        :key="index"
      >
        <div class="videoBoxTop">
          <div class="videoBoxLabel">{{ item.name }}</div>
          <span @click="showMore(item)">查看更多</span>
        </div>
        <div class="videoList">
          <div
            class="videoItem"
            v-for="(childItem, childIndex) in item.videos"
            :key="childIndex"
          >
            <div class="videoItemBox">
              <videoCom :path="childItem.videoUrl"></videoCom>
            </div>
            <div class="videoName hide-one">
              <span class="hide-one">{{ childItem.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="emptyBox" v-if="videoList.length == 0">
        <a-empty description="暂无数据" />
      </div>
    </div>
    <router-view v-else></router-view>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import videoCom from "@/components/videoCom.vue";
import { videoTypeList } from "@/api/videoApi.js";
import { useRouter } from "vue-router";
import { useDetail } from '@/hooks/useDetail';
const router = useRouter();
const { showDetail } = useDetail();
const videoList = ref([]);
const getList = async () => {
  const { code, data } = await videoTypeList();
  if (code == 200) {
    videoList.value = data;
  }
};
// 查看更多
const showMore = (item) => {
  showDetail.value = true;
  router.push(`/trainVideo/trainVideoList?typeName=${item.name}&typeId=${item.id}`);
}
onMounted(() => {
  getList();
});

</script>

<style lang="less" scoped>
.trainVideo {
  .container {
    display: flex;
    flex-direction: column;
  }
  .videoContainer {
    background: #fff;
    margin-top: 40px;
    .videoBoxTop {
      display: flex;
      justify-content: space-between;
      height: 75px;
      align-items: center;
      padding: 0 25px;
      box-sizing: border-box;
      .videoBoxLabel {
        font-size: 28px;
        font-family: AlibabaPuHuiTiB;
        color: #333333;
        font-weight: bold;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          display: inline-block;
          bottom: -10px;
          left: -25px;
          width: 167px;
          height: 5px;
          background: #d90d0d;
        }
      }
      span {
        font-size: 18px;
        font-family: AlibabaPuHuiTiB;
        color: rgba(51, 51, 51, 0.6);
        cursor: pointer;
        &:hover {
          color: @xtxColor;
        }
      }
    }
    .videoList {
      display: flex;
      padding: 40px;
      box-sizing: border-box;
      .videoItem {
        margin-right: 30px;
        width: 260px;
        &:hover {
          .videoName {
            color: @xtxColor;
          }
        }
      }
      .videoName {
        height: 60px;
        width: 100%;
        display: flex;
        align-items: center;
        background: #f5f5f5;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding: 0 17px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
  .videoItemBox {
    width: 260px;
    height: 180px;
  }
  .emptyBox {
    margin-top: 150px;
  }
}
</style>
