import { ref, watch, getCurrentInstance } from "vue";
export const useDetail = () => {
  const vm = getCurrentInstance();
  const showDetail = ref();
  const outPageList = ['/trainVideo', '/train', "/category/sub/1", "/category/sub/2", "/category/sub/3"]
  watch(
    () => vm.proxy.$route,
    e => {
      showDetail.value = outPageList.includes(e.path) ? false : true;
    }
  );
  return { showDetail };
};
